import React, { useState, useEffect } from 'react';
import * as styles from './Navbar.module.scss';
import { Link } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import { CgMenuRight } from '@react-icons/all-files/cg/CgMenuRight';
import { HiOutlineChevronDown } from '@react-icons/all-files/hi/HiOutlineChevronDown';
import { RiHomeLine } from '@react-icons/all-files/ri/RiHomeLine';
import { RiCodeSSlashLine } from '@react-icons/all-files/ri/RiCodeSSlashLine';
import { AiOutlineUser } from '@react-icons/all-files/ai/AiOutlineUser';
import { RiHeart3Line } from '@react-icons/all-files/ri/RiHeart3Line';
import { FiInfo } from '@react-icons/all-files/fi/FiInfo';
import { HiOutlineMail } from '@react-icons/all-files/hi/HiOutlineMail';
import { ImCheckboxUnchecked } from '@react-icons/all-files/im/ImCheckboxUnchecked';

const Navbar = ({ darkBg }) => {
    const [isDark, setIsDark] = useState(darkBg ? true : false);
    const [isMenuShown, setIsMenuShown] = useState(false);
    const [hideNavbar, setHideNavbar] = useState(false);
    const [menuTransitionActivated, setMenuTransitionActivated] = useState(
        false
    );
    const [scrollDir, setScrollDir] = useState('');
    const [screen, setScreen] = useState(null);
    const [hasRan, setHasRan] = useState(false);

    const getScreenSize = () => {
        const width = window.innerWidth;

        if (width >= 1300) {
            return 'xl';
        } else if (width >= 992 && width < 1300) {
            return 'large';
        } else if (width >= 600 && width < 992) {
            return 'small';
        } else {
            return 'xs';
        }
    };

    const handleResize = () => {
        setScreen(getScreenSize());
        if (screen === 'large' || screen === 'xl') {
            setIsMenuShown(false);
            setMenuTransitionActivated(false);
        }
    };

    const updateScreenSize = () => {
        setScreen(getScreenSize());
    };

    const moveMenuToScreen = (isMoveIn) => {
        if (screen !== 'xl') {
            if (isMoveIn) {
                setMenuTransitionActivated(true);
                setIsMenuShown(true);
                document.body.style.top = `-${window.scrollY}px`;
                document.body.style.left = '0';
                document.body.style.width = '100vw';
                document.body.style.height = '100vh';
                document.body.style.position = 'fixed';
            } else {
                setIsMenuShown(false);
                const scrollY = document.body.style.top;
                resetScroll();
                window.scrollTo(0, parseInt(scrollY || '0') * -1);
            }
        }
    };

    const resetScroll = () => {
        document.body.style.position = '';
        document.body.style.top = '';
        document.body.style.left = '';
        document.body.style.width = '';
        document.body.style.height = '';
    };

    useEffect(() => {
        if (!hasRan) {
            setHasRan(true);
            updateScreenSize();
        }
        window.addEventListener('resize', handleResize);

        const threshold = 0;
        let lastScrollY = window.pageYOffset;
        let ticking = false;

        const updateScrollDir = () => {
            const scrollY = window.pageYOffset;

            if (Math.abs(scrollY - lastScrollY) < threshold) {
                ticking = false;
                return;
            }
            setScrollDir(scrollY > lastScrollY ? 'down' : 'up');
            lastScrollY = scrollY > 0 ? scrollY : 0;
            ticking = false;
        };

        const onScroll = () => {
            if (!ticking) {
                window.requestAnimationFrame(updateScrollDir);
                ticking = true;
            }

            const y = window.scrollY;

            if (y <= 0 && darkBg) {
                setIsDark(true);
                setHideNavbar(false);
            } else if (y <= 200 && darkBg) {
                setHideNavbar(true);
            } else {
                setIsDark(false);
                setHideNavbar(scrollDir === 'down' ? true : false);
            }
        };

        window.addEventListener('scroll', onScroll);

        return () => {
            window.removeEventListener('resize', handleResize);
            window.removeEventListener('scroll', onScroll);
        };
    }, [handleResize]);

    return (
        <div
            className={` ${styles.navMainContainer} ${
                isDark ? null : styles.lightHeaderContainer
            }`}
            id={hideNavbar ? styles.navBarHide : styles.navBarShow}
        >
            <div className={styles.navResponsive}>
                <div className={styles.headerLogoContainer}>
                    <Link to="/">
                        {isDark ? (
                            <StaticImage
                                src="../images/logo-light.png"
                                alt="Digital Society Fund Logo"
                                placeholder="blurred"
                                classsName={styles.logo}
                                loading="eager"
                            />
                        ) : (
                            <StaticImage
                                src="../images/logo.png"
                                alt="Digital Society Fund Logo"
                                placeholder="blurred"
                                classsName={styles.logo}
                                loading="eager"
                            />
                        )}
                    </Link>
                </div>

                <div
                    className={` ${styles.headerMenu} ${
                        isMenuShown
                            ? styles.headerMenuSmallShown
                            : styles.headerMenuSmallHidden
                    } ${
                        menuTransitionActivated
                            ? styles.transitionActivated
                            : null
                    }  `}
                >
                    <div className={styles.headerBg}></div>

                    <div
                        className={styles.closeButton}
                        onClick={() => moveMenuToScreen(false)}
                    >
                        X
                    </div>

                    <Link
                        to="/"
                        className={
                            isDark ? styles.menuItemDark : styles.menuItemLight
                        }
                        activeClassName={
                            isDark
                                ? styles.selectedMenuItemDark
                                : styles.selectedMenuItemLight
                        }
                        id={styles.firstMenuItem}
                        onClick={() => resetScroll()}
                    >
                        <RiHomeLine className={styles.icon} />
                        Home
                    </Link>

                    <Link
                        to="/about"
                        className={
                            isDark ? styles.menuItemDark : styles.menuItemLight
                        }
                        activeClassName={
                            isDark
                                ? styles.selectedMenuItemDark
                                : styles.selectedMenuItemLight
                        }
                        onClick={() => resetScroll()}
                    >
                        <FiInfo className={styles.icon} />
                        About
                    </Link>

                    <div
                        className={
                            isDark
                                ? styles.dropdownToggleDark
                                : styles.dropdownToggleLight
                        }
                    >
                        <div className={styles.dropdownTitle}>
                            <RiCodeSSlashLine className={styles.icon} />
                            Initiatives
                        </div>

                        <div
                            className={
                                isDark
                                    ? styles.dropdownDark
                                    : styles.dropdownLight
                            }
                        >
                            <Link
                                to="/scholarships"
                                className={
                                    isDark
                                        ? styles.dropdownMenuDark
                                        : styles.dropdownMenuLight
                                }
                                activeClassName={
                                    isDark
                                        ? styles.selectedMenuItemDark
                                        : styles.selectedMenuItemLight
                                }
                                onClick={() => resetScroll()}
                            >
                                <div className={styles.dropdownItem}>
                                    <ImCheckboxUnchecked
                                        className={styles.box}
                                    />
                                    All Scholarships
                                </div>
                            </Link>

                            <Link
                                to="/scholarship/futuremakers-scholarship"
                                className={`${
                                    isDark
                                        ? styles.dropdownMenuDark
                                        : styles.dropdownMenuLight
                                } ${styles.dropdownMenuShift}`}
                                activeClassName={
                                    isDark
                                        ? styles.selectedMenuItemDark
                                        : styles.selectedMenuItemLight
                                }
                                onClick={() => resetScroll()}
                            >
                                <div className={styles.dropdownItem}>
                                    <ImCheckboxUnchecked
                                        className={styles.box}
                                    />
                                    Undergraduate Degrees
                                </div>
                            </Link>

                            <Link
                                to="/scholarship/professional-certifications"
                                className={`${
                                    isDark
                                        ? styles.dropdownMenuDark
                                        : styles.dropdownMenuLight
                                } ${styles.dropdownMenuShift}`}
                                activeClassName={
                                    isDark
                                        ? styles.selectedMenuItemDark
                                        : styles.selectedMenuItemLight
                                }
                                onClick={() => resetScroll()}
                            >
                                <div className={styles.dropdownItem}>
                                    <ImCheckboxUnchecked
                                        className={styles.box}
                                    />
                                    Professional Certifications
                                </div>
                            </Link>

                            <Link
                                to="/programmes"
                                className={
                                    isDark
                                        ? styles.dropdownMenuDark
                                        : styles.dropdownMenuLight
                                }
                                activeClassName={
                                    isDark
                                        ? styles.selectedMenuItemDark
                                        : styles.selectedMenuItemLight
                                }
                                onClick={() => resetScroll()}
                            >
                                <div className={styles.dropdownItem}>
                                    <ImCheckboxUnchecked
                                        className={styles.box}
                                    />
                                    All Programmes
                                </div>
                            </Link>

                            <Link
                                to="/programme/k-12-development-programme"
                                className={`${
                                    isDark
                                        ? styles.dropdownMenuDark
                                        : styles.dropdownMenuLight
                                } ${styles.dropdownMenuShift}`}
                                activeClassName={
                                    isDark
                                        ? styles.selectedMenuItemDark
                                        : styles.selectedMenuItemLight
                                }
                                onClick={() => resetScroll()}
                            >
                                <div className={styles.dropdownItem}>
                                    <ImCheckboxUnchecked
                                        className={styles.box}
                                    />
                                    K-12 STEM Development
                                </div>
                            </Link>

                            <Link
                                to="/programme/neumann-fellowship"
                                className={`${
                                    isDark
                                        ? styles.dropdownMenuDark
                                        : styles.dropdownMenuLight
                                } ${styles.dropdownMenuShift}`}
                                activeClassName={
                                    isDark
                                        ? styles.selectedMenuItemDark
                                        : styles.selectedMenuItemLight
                                }
                                onClick={() => resetScroll()}
                            >
                                <div className={styles.dropdownItem}>
                                    <ImCheckboxUnchecked
                                        className={styles.box}
                                    />
                                    The Neumann Fellowship
                                </div>
                            </Link>
                        </div>
                    </div>

                    <Link
                        to="/donate"
                        className={
                            isDark ? styles.menuItemDark : styles.menuItemLight
                        }
                        activeClassName={
                            isDark
                                ? styles.selectedMenuItemDark
                                : styles.selectedMenuItemLight
                        }
                        onClick={() => resetScroll()}
                    >
                        <RiHeart3Line className={styles.icon} />
                        Donate
                    </Link>

                    <Link
                        to="/contact"
                        className={
                            isDark ? styles.menuItemDark : styles.menuItemLight
                        }
                        activeClassName={
                            isDark
                                ? styles.selectedMenuItemDark
                                : styles.selectedMenuItemLight
                        }
                        onClick={() => resetScroll()}
                    >
                        <HiOutlineMail className={styles.icon} />
                        Contact
                    </Link>
                </div>

                <div
                    className={`${styles.menuIconContainer} ${
                        isMenuShown ? styles.hideMenu : null
                    }`}
                >
                    <CgMenuRight
                        className={
                            isDark ? styles.menuIconDark : styles.menuIconLight
                        }
                        onClick={() => moveMenuToScreen(true)}
                    />
                </div>
            </div>
        </div>
    );
};

export default Navbar;

// <Link
// to="/team"
// className={
//     isDark ? styles.menuItemDark : styles.menuItemLight
// }
// activeClassName={
//     isDark
//         ? styles.selectedMenuItemDark
//         : styles.selectedMenuItemLight
// }
// onClick={() => resetScroll()}
// >
// <AiOutlineUser className={styles.icon} />
// Community
// </Link>
