import React from 'react';
import * as styles from './Footer.module.scss';
import { StaticImage } from 'gatsby-plugin-image';
import { FaFacebookF } from '@react-icons/all-files/fa/FaFacebookF';
import { RiInstagramFill } from '@react-icons/all-files/ri/RiInstagramFill';
import { SiGmail } from '@react-icons/all-files/si/SiGmail';
import { FaLinkedinIn } from '@react-icons/all-files/fa/FaLinkedinIn';
import { FaPhoneAlt } from '@react-icons/all-files/fa/FaPhoneAlt';
import { Link } from 'gatsby';

function Footer() {
    return (
        <div className={styles.footerContainer}>
            <div className={styles.innerFooterContainer}>
                <div className={styles.socials}>
                    <Link to="/" className={styles.picture}>
                        <StaticImage
                            src="../images/logo.png"
                            alt="Digital Society Fund"
                            objectFit="contain"
                        />
                    </Link>

                    <div className={styles.socialsContainer}>
                        <a
                            className={styles.social}
                            href="https://www.facebook.com/digitalsocietyfund"
                            target="_blank"
                        >
                            <FaFacebookF />{' '}
                            <span className={styles.socialText}>Facebook</span>{' '}
                        </a>
                        <a
                            className={styles.social}
                            href="https://www.instagram.com/digitalsocietyfund/"
                            target="_blank"
                        >
                            <RiInstagramFill />
                            <span className={styles.socialText}>
                                Instagram
                            </span>{' '}
                        </a>
                        <a
                            className={styles.social}
                            href="https://www.linkedin.com/company/digitalsocietyfund/"
                            target="_blank"
                        >
                            <FaLinkedinIn />{' '}
                            <span className={styles.socialText}>LinkedIn</span>{' '}
                        </a>
                        <a
                            className={styles.social}
                            href="mailto:shawnyip@digitalsocietyfund.org"
                            target="_blank"
                        >
                            <SiGmail />{' '}
                            <span className={styles.socialText}>Email</span>{' '}
                        </a>
                        <a
                            className={styles.social}
                            href="https://wa.me/60126710102?text=Hello%20Shawn%20from%20DSF%20Team%21%20%0A%0AI%20am%20contacting%20you%20in%20regards%20to%20the%20Digital%20Society%20Fund%20initiatives."
                            target="_blank"
                        >
                            <FaPhoneAlt />{' '}
                            <span className={styles.socialText}>Whatsapp</span>{' '}
                        </a>
                    </div>
                </div>

                <div className={styles.links}>
                    <h5>Information</h5>
                    <div className={styles.linksContainer}>
                        <Link className={styles.link} to="/about">
                            About us
                        </Link>
                        <Link className={styles.link} to="/team">
                            Our Team
                        </Link>
                    </div>
                </div>

                <div className={styles.links}>
                    <h5>Initiatives</h5>
                    <div className={styles.linksContainer}>
                        <Link className={styles.link} to="/scholarships">
                            Our scholarships
                        </Link>
                        <Link className={styles.link} to="/programmes">
                            Our programmes
                        </Link>
                    </div>
                </div>

                <div className={styles.links}>
                    <h5>Get in Touch</h5>
                    <div className={styles.linksContainer}>
                        <Link className={styles.link} to="/contact">
                            Contact us
                        </Link>
                    </div>
                </div>
            </div>

            <div className={styles.footnotesContainer}>
                <span>
                    &#169; {new Date().getFullYear()} Digital Society Fund. All
                    rights reserved.
                </span>
            </div>
        </div>
    );
}

export default Footer;
