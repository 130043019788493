// extracted by mini-css-extract-plugin
export var footerContainer = "Footer-module--footerContainer--3IMQA";
export var innerFooterContainer = "Footer-module--innerFooterContainer--1ry-G";
export var socials = "Footer-module--socials--1ZdmU";
export var picture = "Footer-module--picture--3-2ai";
export var socialsContainer = "Footer-module--socialsContainer--3TUNM";
export var social = "Footer-module--social--K7PWK";
export var socialText = "Footer-module--socialText--3PXXn";
export var links = "Footer-module--links--3iYF1";
export var linksContainer = "Footer-module--linksContainer--3E-oI";
export var link = "Footer-module--link--1KQCJ";
export var contactLink = "Footer-module--contactLink--2pVhi";
export var icon = "Footer-module--icon--2Zwla";
export var footnotesContainer = "Footer-module--footnotesContainer--2dfn9";