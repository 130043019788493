import React from 'react';
import Navbar from './Navbar';
import * as styles from './Layout.module.scss';
import Footer from './Footer';
import '@fontsource/poppins';
import '@fontsource/poppins/500.css';
import '@fontsource/poppins/600.css';
import '@fontsource/poppins/700.css';
import '@fontsource/poppins/300.css';
import { Helmet } from 'react-helmet';

const Layout = ({ children, darkBg }) => {
    return (
        <div>
            <Helmet>
                <title>Digital Society Fund</title>
            </Helmet>
            <Navbar darkBg={darkBg} />
            {children}
            <Footer />
        </div>
    );
};

export default Layout;
